import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const WarningBlock = makeShortcode("WarningBlock");
const MyImage = makeShortcode("MyImage");
const InfoBlock = makeShortcode("InfoBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "saving",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#saving",
        "aria-label": "saving permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Saving`}</h3>
    <p>{`If you haven't mechanically hit `}<inlineCode parentName="p">{`Command-S`}</inlineCode>{` so far, it's time to do so!`}</p>
    <WarningBlock mdxType="WarningBlock">
  Until the project is saved, all the changes are in memory.<br />
  Saving will store them permanently in your local database and <u>does not require you to be online</u>.
    </WarningBlock>
    <p>{`Alternatively, you can hover the `}<em parentName="p">{`save`}</em>{` icon and click it.`}</p>
    <MyImage src="saving-1.png" alt="Saving" className="w-75" mdxType="MyImage" />
    <h3 {...{
      "id": "pushing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pushing",
        "aria-label": "pushing permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Pushing`}</h3>
    <p>{`Pushing is the process of uploading your latest changes to CodeStory servers.`}</p>
    <MyImage src="saving-2.png" alt="Saving" className="w-75" mdxType="MyImage" />
    <p>{`If you're part of a team, keep in mind that pushing your changes does not make them visible to your team yet!`}</p>
    <h3 {...{
      "id": "publishing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#publishing",
        "aria-label": "publishing permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Publishing`}</h3>
    <p>{`Publishing is the required step to make your changes visible to your team.`}</p>
    <MyImage src="saving-3.png" alt="Saving" className="w-75" mdxType="MyImage" />
    <p>{`If your notes contain Live Code Views, it's critical to ensure that their content will be visible by others.`}</p>
    <InfoBlock mdxType="InfoBlock">The Publishing step ensures they are by checking that changes in corresponding files have been pushed to the remote repository.<br />
  <b>This operation is accomplished using your local git</b>.</InfoBlock>
    <p>{`Clicking the Outbox icon:`}</p>
    <MyImage src="saving-4.png" alt="Saving" mdxType="MyImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      